<template>
  <div class="pb-10">
    <v-img
      class="mt-n8 d-block"
      height="400"
      width="100%"
      :src="about.group1.image"
    ></v-img>
    <v-container class="mb-15 div-about">
      <v-row>
        <v-col>
          <div style="text-align: center;" class="py-5">
            <label class="yellow--text text--darken-3 font-weight-bold">
              บริษัท มูฟไว จำกัด
            </label>
            <label class="d-block white--text my-5">
              {{ about.group1.text }}
            </label>
            <label class="yellow--text text--darken-3 font-weight-bold">
              "มูฟไว มั่นใจ ประกันภัย 100%"
            </label>
          </div>
        </v-col>
      </v-row>
      <!-- Start my vision -->
      <v-row class="mx-0 mt-3">
            <v-col class="col-service" cols="12" md="6">
                <v-img
                    height="280"
                    :src="about.group2.image"
                ></v-img>
            </v-col>
            <v-col class="col-service" cols="12" md="6">
                <div style="width: 100%">
                <label class="d-block font-weight-bold yellow--text text--darken-3">วิสัยทัศน์ของเรา</label>
                <hr class="my-2 rounded"/>
                <label class="white--text d-block mb-8">
                  {{ about.group2.text }}
                </label>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-0 mt-3">
            <v-col class="col-service" cols="12" md="6">
                <div style="width: 100%">
                    <label class="d-block font-weight-bold yellow--text text--darken-3">พันธกิจของเรา</label>
                    <hr class="my-2 rounded"/>
                    <label class="white--text d-block mb-8">
                      {{ about.group3.text }}
                    </label>
                </div>
            </v-col>
            <v-col class="col-service" cols="12" md="6">
                <v-img
                    height="280"
                    :src="about.group3.image"
                ></v-img>
            </v-col>
        </v-row>
    </v-container>

    <!-- Util conponents -->
    <dialog-error
        ref="dialogError"
        :topic="'Error'"
        :text="errorMessage"
    />
  </div>
</template>

<script>
import axios from 'axios';
import DialogError from '../components/DialogError.vue';

export default {
  data: () => ({
    about: {
      group1: {},
      group2: {},
      group3: {}
    },
    errorMessage: ''
  }),
  async mounted(){
      try{
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/about-us');
        this.about = response.data;
      }catch(error){
        this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
        this.$refs.dialogError.show();
      }
  },
  components: {
    DialogError
  }
};
</script>

<style scoped>
  hr.rounded {
    border-top: 1px solid #C49C4F;
    border-radius: 5px;
  }
  .div-about{
    width: 50%;
  }

  @media (max-width: 600px){
    .div-about{
      width: 100%;
      border: none;
    }
  }
</style>